import styles from '../styles/About.module.css';

const About = () => {
  return (
    <div className={styles.main_content}>
      <div className={styles.spacer}></div>
      <div id={styles.headline_container}>
        <img src="/icons/flower.svg" />
        <p>We are a Montreal-based innovation lab dedicated to bringing our own mobile and web digital product ideas
          to life. Our mission is to build high-quality, delightful products that enhance the everyday experiences
          of users worldwide.</p>
        <img src="/icons/stairs.svg" />
      </div>
      <div className={styles.spacer}></div>
    </div>
  )
}
export default About;