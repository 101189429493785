import { NavLink } from "react-router-dom";
import styles from '../styles/Navbar.module.css';

const Navbar = () => {
      return (
            <div className={styles.header_footer}>
                  <img src="/icons/logo.svg" className={styles.header_footer_items} id={styles.logo} />
                  <div className={styles.header_footer_items} id={styles.nav_links_container}>
                        <NavLink to="/" className={({ isActive }) =>
                              `${styles.nav_link} ${isActive ? styles.active_link : ''}`
                        }>Home</NavLink>
                        <NavLink to="/about" className={({ isActive }) =>
                              `${styles.nav_link} ${isActive ? styles.active_link : ''}`
                        }>About</NavLink>
                  </div>
            </div>
      )
}
export default Navbar;