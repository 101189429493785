import styles from "../../styles/budgetmate/LandingPage.module.css";

export default function LandingPage() {
    return (
        <div className={styles.mainContent}>
            <img id={styles.appIcon} src="/appIcons/budgetmate-inverted.png" />

            <div className={styles.headlineSection}>
                <p className={styles.mainHeadline}>Welcome to Budgetmate</p>
                <p className={styles.headline}>Take control of your finances effortlessly.</p>
                <a href="#" target="_blank">
                    <img src="/images/app-store.svg" id={styles.appStoreImage} />
                </a>
            </div>

            <div className={styles.contentSection}>
                

                <div className={styles.halfSection}>
                    <div className={styles.imageContainer}>
                        <img src="/budgetmate-mocks/categorize.png" />
                    </div>
                </div>

                <div className={styles.halfSection}>
                    <p>Classify transactions as 'Wants' or 'Needs'</p>
                </div>
            </div>

            <div className={styles.contentSection}>
                <div className={styles.halfSection}>
                    <div className={styles.imageContainer}>
                        <img src="/budgetmate-mocks/transactions.png" />
                    </div>
                </div>

                <div className={styles.halfSection}>
                    <p>Track expenses by syncing your bank accounts in just a few taps</p>
                </div>
            </div>

            <div className={styles.contentSection}>
                

                <div className={styles.halfSection}>
                    <div className={styles.imageContainer}>
                        <img src="/budgetmate-mocks/budget.png" />
                    </div>
                </div>

                <div className={styles.halfSection}>
                    <p>See how much you spend and save each month</p>
                </div>
            </div>
        </div>
    );
}