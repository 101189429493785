import styles from '../styles/Home.module.css';

const Home = () => {
  return (
    <div className={styles.main_content}>
      <div className={styles.spacer}></div>
      <div id={styles.headline_container}>
        <img src="/icons/star-rect.svg" />
        <p>The lab for tomorrow’s <b>bold</b> digital products.</p>
      </div>
      <div className={styles.grid_container}>
        <div className={styles.grid_item} id={styles.montreal_item}>
          <p className={styles.white_text}>Proudly from Montreal</p>
        </div>
        <div className={`${styles.grid_item} ${styles.item2}`}>
          <p id={styles.binary_item_p}>
            01101001<br />01101110<br />01101110<br />01101111<br />01110110<br />01100001<br />01110100<br />01101001<br />01101111<br />01101110
          </p>
        </div>
        <div className={styles.grid_item} id={styles.budgetmate_app}>
          <p className={styles.white_text}>Budgetmate</p>
        </div>
        <div className={`${styles.grid_item} ${styles.transparent_item}`}>
          <img src="/icons/star.svg" />
        </div>
        <div className={styles.grid_item} id={styles.stay_cool_item}></div>
        <div className={`${styles.grid_item} ${styles.transparent_item} ${styles.no_show_mobile}`}>
          <img src="/icons/planet.svg" />
        </div>
        <div className={styles.grid_item} id={styles.sport_item}>
          <p className={styles.white_text}>Reppy</p>
        </div>
        <div className={`${styles.grid_item} ${styles.item8}`} id={styles.podai_item}>
          <p className={styles.white_text}>PodAI</p>
        </div>
      </div>
      <div className={styles.spacer}></div>
    </div>
  )
}
export default Home;