import styles from '../styles/Footer.module.css';

interface FooterProps {
    textColor: string
}

const Footer = ({ textColor }: FooterProps) => {
    return (
        <div className={styles.header_footer} style={{color: textColor}}>
            <p className={styles.header_footer_items}>© 2024, Bytemark Labs Technologies Inc. All rights reserved.</p>
            <p className={styles.header_footer_items}>contact@bytemarklabs.com</p>
        </div>
    )
}
export default Footer;